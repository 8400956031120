import { Checkbox, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { FaCamera } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import api from "../../api/api";
import hamburger from "../../assets/icons/black-hamburger.svg";
import { BlackButton } from "../../components/black-button";
import { environment } from "../../environments/environment";



interface CampaignImagesProp {
  image_url: string;
  sort_id: number;
  uuid?: string;
  ID?: number;
  createdAt?: string;
  updatedAt?: string;
  DeletedAt?: string;
  campaign_id?: number;
  campaign_uuid?: string;
  image_public_id?: string;
  image_asset_id?: string;
  gallery_enabled?: boolean;
  image_title?: string;
  image_description?: string;
  isNew?: boolean;
}
interface DraggableImageProps {
  image: CampaignImagesProp;
  index: number;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  deleteImage: (identifier: string, isNew: boolean) => void;
}

const DraggableImage: React.FC<DraggableImageProps> = ({
  image,
  index,
  moveImage,
  deleteImage,
}) => {
  const [, ref] = useDrag({
    type: "IMAGE",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "IMAGE",
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const cloudinaryImageUrl = image.image_url.includes("cloudinary.com")
    ? image.image_url.replace("/upload/", "/upload/f_auto/")
    : image.image_url;

  return (
    <div
      ref={(node) => ref(drop(node))}
      className="relative cursor-move h-36 w-36"
    >
      <img
        src={cloudinaryImageUrl}
        alt={`Campaign Image ${index + 1}`}
        className="w-full h-auto rounded-md"
      />
      <button
        onClick={() =>
          deleteImage(
            image?.isNew ? index.toString() : image.uuid || "",
            !!image.isNew
          )
        }
        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition-colors"
      >
        <BsTrash size={20} />
      </button>
    </div>
  );
};

export const AdminNewCampaign = () => {
  const { uuid } = useParams(); //in case of viewing single campaign to edit
  const navigate = useNavigate();
  const [imageUrls, setImageUrls] = useState<string | null>(null);
  // const [newImageUrls, setNewImageUrls] = useState<File[]>([]);
  const [campaignImages, setCampaignImages] = useState<CampaignImagesProp[]>(
    []
  );
  const [newImages, setNewImages] = useState<File[]>([]);
  const [selectedCategories, setCategories] = useState<
    { label: string; value: string }[]
  >([]);

  const [selectedSizeTypes, setSizeTypes] = useState<string[]>([]);
  const [size, setSize] = useState<any>([]);
  const [size_men_shoe, setSize_Men] = useState<any>([]);
  const [size_women_shoe, setSize_Women] = useState<any>([]);

  const [updateImage, setUpdateImage] = useState<any>("");
  const [updatesField, setUpdatesField] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [categoriesList, setCategoriesList] = useState<
    { label: string; value: string }[]
  >([]);

  const {
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm();

  /**
   * Converts a single image file to a base64 string.
   * @param file - The image file to convert.
   * @returns A promise that resolves to the base64 string.
   */
  const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        // Remove "data:" prefix and everything up to and including the first comma
        const base64String = result.replace("data:", "").replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const submitMe = async (values: any) => {
    if (
      Number(values.production_goal_order_number) >
      Number(values.end_goal_order_number)
    ) {
      return toast.error(
        "Production Goal Order Number should be less than End Goal Order Number"
      );
    }
    setLoading(true);
    let payload = { ...values };
    // payload.image_url = imageUrls;
    payload.production_goal_price = Number(values.production_goal_price);
    payload.end_goal_order_number = Number(values.end_goal_order_number);
    payload.end_goal_price = Number(values.end_goal_price);
    payload.minimum_quantity = Number(values.minimum_quantity);
    payload.production_goal_order_number = Number(
      values.production_goal_order_number
    );

    if (selectedSizeTypes.includes("size")) {
      payload.size = size.map((x: any) => x.value);
    }
    if (selectedSizeTypes.includes("men")) {
      payload.size_men_shoe = size_men_shoe.map((x: any) => x.value).join(",");
    }
    if (selectedSizeTypes.includes("women")) {
      payload.size_women_shoe = size_women_shoe
        .map((x: any) => x.value)
        .join(",");
    }

    // console.log("newImages", newImages);

    // Handle new images
    const imagePromises = newImages.map(async (file, index) => {
      const bytes = await convertImageToBase64(file);
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      return {
        media_data: bytes,
        media_name: fileNameWithoutExtension,
        media_type: file.type,
        //   sort_id:
        //     campaignImages.find(
        //       (img) => img.image_url === URL.createObjectURL(file)
        //     )?.sort_id ?? campaignImages.length + index + 1,
        // };
        sort_id: campaignImages.length + index + 1,
      };
    });

    const newMediaData = await Promise.all(imagePromises);

    // console.log(newMediaData);

    // Prepare the final payload with updated image data
    const finalImageData = campaignImages
      .filter((img) => !img.isNew)
      .map((img, index) => ({
        uuid: img.uuid,
        sort_id: index + 1,
        // Include other necessary fields
      }));

    const combinedImages = [...finalImageData, ...newMediaData];

    // Reassign sort_ids to ensure continuous numbering from 1
    payload.images = combinedImages.map((img, index) => ({
      ...img,
      sort_id: index + 1, // Final sort_id starts from 1
    }));

    // console.log("payload", payload.images);

    // console.log("payload", payload.images);
    // console.log(payload);

    if (uuid) {
      //when editing
      try {
        payload.campaign_uuid = uuid;

        if (selectedCategories[0]?.value) {
          payload.category = selectedCategories[0]?.value;
        }

        const response = await api.put(
          `${environment.baseUrl}/admin/campaign/edit`,
          payload
        );

        if (response.data.success) {
          // console.log(response.data);
          toast.success(response.data.message);
          navigate("/admin/campaigns");
        } else {
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      //when creating
      try {
        payload.category = selectedCategories[0].value;

        const response = await api.post(
          `${environment.baseUrl}/admin/campaign/create`,
          payload
        );

        if (response.data.success) {
          toast.success(response.data.message);
          navigate("/admin/campaigns");
        } else {
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  // const handleImage_s = (file_s: any) => {
  //   // Handle image upload here
  //   if (file_s && file_s[0]) {
  //     for (const file of file_s) {
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       formData.append("upload_preset", "cqom5ijc");

  //       axios
  //         .post(
  //           `https://api.cloudinary.com/v1_1/dlicyptzz/image/upload`,
  //           formData
  //         )
  //         .then((response) => {
  //           setImageUrls((prevImageUrls) => {
  //             if (prevImageUrls) {
  //               return prevImageUrls + "," + response.data.secure_url;
  //             } else {
  //               return response.data.secure_url;
  //             }
  //           });
  //         })
  //         .catch((error) => {
  //           console.error("Error uploading image:", error);
  //         });
  //     }
  //   }
  // };

  // * NEW Functions for the drag and drop functionality
  const moveImage = (fromIndex: number, toIndex: number) => {
    setCampaignImages((prevImages) => {
      const updatedImages = [...prevImages];
      const [movedImage] = updatedImages.splice(fromIndex, 1);

      // Insert the moved image at the new position
      updatedImages.splice(toIndex, 0, movedImage);

      // Reassign sort_ids starting from 1
      return updatedImages.map((img, index) => ({
        ...img,
        sort_id: index + 1, // Sort IDs starting from 1
      }));
    });
  };

  const handleNewImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImageFiles = Array.from(files);
      setNewImages((prevImages) => [...prevImages, ...newImageFiles]);

      setCampaignImages((prevImages) => {
        // Get the last sort_id (or start from 0)
        const lastSortId =
          prevImages.length > 0
            ? Math.max(...prevImages.map((img) => img.sort_id))
            : 0;

        const newCampaignImages = newImageFiles.map((file, index) => ({
          uuid: `new-${Date.now()}-${index}`,
          image_url: URL.createObjectURL(file),
          sort_id: lastSortId + index + 1, // Continue from the last sort_id
          isNew: true,
        }));

        // console.log(newCampaignImages);

        // Combine new and existing images, filtering duplicates
        const updatedImages = [
          ...prevImages,
          ...newCampaignImages.filter(
            (newImage) =>
              !prevImages.some((prev) => prev.image_url === newImage.image_url)
          ),
        ];

        // console.log(updatedImages);

        // Re-assign sort_id to ensure uniqueness and start from 1
        return updatedImages.map((image, index) => ({
          ...image,
          sort_id: index + 1, // Sort IDs start from 1
        }));
      });
    }
  };

  const deleteImage = async (identifier: string, isNew: boolean) => {
    if (isNew) {
      // Handle deleting new images
      const indexToDelete = parseInt(identifier);
      setCampaignImages((prevImages) => {
        const updatedImages = prevImages.filter(
          (_, index) => index !== indexToDelete
        );
        // Adjust sort_ids after deletion
        return updatedImages.map((img, index) => ({
          ...img,
          sort_id: index + 1, // Sort IDs start from 1
        }));
      });
      setNewImages((prevNewImages) =>
        prevNewImages.filter((_, index) => index !== indexToDelete)
      );
    } else {
      // Handle deleting existing images
      setCampaignImages((prevImages) => {
        const updatedImages = prevImages.filter(
          (img) => img.uuid !== identifier
        );
        // Adjust sort_ids after deletion
        return updatedImages.map((img, index) => ({
          ...img,
          sort_id: index + 1, // Sort IDs start from 1
        }));
      });
      try {
        const response = await api.delete(
          `${environment.baseUrl}/admin/campaign/image/delete/${identifier}`
        );
        if (response.data.success) {
          toast.success(response.data.message);
        }
      } catch (error) {
        console.error("Error deleting image:", error);
        toast.error("Unable to delete image, try again.");
      }
    }
  };

  const handleUpdateImage = (event: any) => {
    const files = event.target.files;
    // Handle image upload here
    if (files && files[0]) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("upload_preset", "cqom5ijc");

      axios
        .post(
          `https://api.cloudinary.com/v1_1/dlicyptzz/image/upload`,
          formData
        )
        .then((response) => {
          setUpdateImage(response.data.secure_url);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    }
  };

  const fetchCategoriesList = async () => {
    try {
      const response = await api.get(`${environment.baseUrl}/admin/categories`);

      if (response.data.code == 200) {
        setCategoriesList(
          response.data.data.all_categories.map((x: any) => ({
            label: x.name,
            value: x.short_name,
          }))
        );
        if (uuid) {
          fetchCampaignDetails();
        }
      }
    } catch (error) {
      navigate("/campaigns");
    }
  };

  const fetchCampaignDetails = async () => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch/single/${uuid}`
      );

      if (response.data.success) {
        const data_ = response.data.data.campaign;
        setImageUrls(data_.image_url);
        setValue("campaign_title", data_.campaign_title);
        setValue("product_title", data_.product_title);
        setValue("production_goal_price", data_.production_goal_price);
        setValue(
          "production_goal_order_number",
          data_.production_goal_order_number
        );
        setValue("end_goal_price", data_.end_goal_price);
        setValue("end_goal_order_number", data_.end_goal_order_number);
        setValue("campaign_deadline", data_.campaign_deadline.split("T")[0]);
        setValue("location", data_.location);
        setValue("campaign_description", data_.campaign_description);
        setValue("minimum_quantity", data_.minimum_quantity);
        setValue("campaign_updates", data_.campaign_updates);
        setCategories([
          categoriesList.find((x) => x.value === data_.category)!,
        ]);

        if (data_.size) {
          setSizeTypes((prev) => prev.concat("size"));
          setSize(data_.size);
        }
        if (data_.size_men_shoe) {
          setSizeTypes((prev) => prev.concat("men"));
          setSize_Men(
            data_.size_men_shoe
              .split(",")
              .map((x: any) => ({ value: x, label: x }))
          );
        }
        if (data_.size_women_shoe) {
          setSizeTypes((prev) => prev.concat("women"));
          setSize_Women(
            data_.size_women_shoe
              .split(",")
              .map((x: any) => ({ value: x, label: x }))
          );
        }

        if (response.data.data.campaign_images) {
          const apiImages = response.data.data.campaign_images;
          const sortedImages = apiImages
            .sort(
              (a: CampaignImagesProp, b: CampaignImagesProp) =>
                a.sort_id - b.sort_id
            )
            .map((img: CampaignImagesProp) => ({ ...img, isNew: false }));

          // console.log(sortedImages);

          setCampaignImages(sortedImages);
        }
      }
    } catch (error) {
      navigate("/admin/campaigns");
    }
  };

  const submitUpdate = async () => {
    try {
      const response = await api.post(
        `${environment.baseUrl}/admin/campaign-update`,
        {
          text: updatesField,
          image_url: updateImage,
          campaign_uuid: uuid,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setUpdatesField("");
        setUpdateImage(null);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchCategoriesList();
  }, []);

  // const [campaignDetails, setCampaignDetails] = useState<any>(initialValues);

  return (
    <section className="font-montserrat">
      <div className="flex items-center gap-3">
        <img src={hamburger} alt="campaigns-icon" className="w-[18px]" />
        <h5 className="text-[22px] font-bold">Campaigns</h5>
      </div>

      <form
        onSubmit={handleSubmit(submitMe)}
        className="mt-7 bg-white gap-y-5 gap-x-[5%] text-black p-8 tall:py-[60px] xl:px-9 rounded-[18px] grid items-start xl:grid-cols-2"
      >
        <div className="grid gap-y-5 items-start">
          <div className="flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">CAMPAIGN TITLE</h6>

            <TextField
              className="admin_input_1"
              placeholder="Campaign title Name...."
              {...register("campaign_title", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">PRODUCT TITLE</h6>

            <TextField
              className="admin_input_1"
              placeholder="Product title Name...."
              {...register("product_title", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              PRODUCTION GOAL PRICE
            </h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("production_goal_price", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              PRODUCTION GOAL ORDER NUMBER
            </h6>
            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("production_goal_order_number", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">END GOAL PRICE</h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("end_goal_price", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              END GOAL ORDER NUMBER
            </h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("end_goal_order_number", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">MINIMUM QUANTITY</h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("minimum_quantity", { required: true })}
            />
          </div>

          <h6 className="font-bold text-sm md:text-base">
            Select one or multiple categories
          </h6>

          <Select
            isMulti={true}
            options={categoriesList}
            value={selectedCategories}
            onChange={(e: any) => setCategories(e)}
            placeholder="Select one or multiple categories"
          />

          <h6 className="font-bold text-sm md:text-base">SIZE</h6>
          <Accordion
            expanded={true}
            className="!rounded-[10px] [border:1px_solid_#CCCCCC_!important] !m-0 [box-shadow:none_!important]"
          >
            <AccordionSummary
              className="!px-6"
              expandIcon={<div className="expand-icon-accordion"></div>}
              id="panel1a-header"
            >
              Select size
            </AccordionSummary>
            <AccordionDetails>
              <div className="grid gap-4">
                <div className="flex items-center">
                  <Checkbox
                    onClick={() => {
                      if (selectedSizeTypes.includes("men")) {
                        setSizeTypes((prev) => prev.filter((x) => x !== "men"));
                      } else {
                        setSizeTypes((prev) => prev.concat("men"));
                      }
                    }}
                    checked={selectedSizeTypes.includes("men")}
                  />
                  <div className="flex items-center gap-3">
                    <Select
                      isMulti={true}
                      onMenuOpen={() =>
                        setSizeTypes((prev) => prev.concat("men"))
                      }
                      options={[
                        { value: 5, label: 5 },
                        { value: 5.5, label: 5.5 },
                        { value: 6, label: 6 },
                        { value: 6.5, label: 6.5 },
                        { value: 7, label: 7 },
                        { value: 7.5, label: 7.5 },
                        { value: 8, label: 8 },
                        { value: 8.5, label: 8.5 },
                        { value: 9, label: 9 },
                        { value: 9.5, label: 9.5 },
                        { value: 10, label: 10 },
                        { value: 10.5, label: 10.5 },
                        { value: 11, label: 11 },
                        { value: 11.5, label: 11.5 },
                        { value: 12, label: 12 },
                        { value: 13, label: 13 },
                        { value: 14, label: 14 },
                      ]}
                      value={size_men_shoe}
                      onChange={(e) => setSize_Men(e)}
                      placeholder="Select an option"
                    />
                    <span className="text-xs">Men's Shoe</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Checkbox
                    onChange={() => {
                      if (selectedSizeTypes.includes("women")) {
                        setSizeTypes((prev) =>
                          prev.filter((x) => x !== "women")
                        );
                      } else {
                        setSizeTypes((prev) => prev.concat("women"));
                      }
                    }}
                    checked={selectedSizeTypes.includes("women")}
                  />
                  <div className="flex items-center gap-3">
                    <Select
                      isMulti={true}
                      onMenuOpen={() =>
                        setSizeTypes((prev) => prev.concat("women"))
                      }
                      options={[
                        { value: 5, label: 5 },
                        { value: 5.5, label: 5.5 },
                        { value: 6, label: 6 },
                        { value: 6.5, label: 6.5 },
                        { value: 7, label: 7 },
                        { value: 7.5, label: 7.5 },
                        { value: 8, label: 8 },
                        { value: 8.5, label: 8.5 },
                        { value: 9, label: 9 },
                        { value: 9.5, label: 9.5 },
                        { value: 10, label: 10 },
                        { value: 10.5, label: 10.5 },
                        { value: 11, label: 11 },
                        { value: 11.5, label: 11.5 },
                        { value: 12, label: 12 },
                        { value: 13, label: 13 },
                        { value: 14, label: 14 },
                      ]}
                      value={size_women_shoe}
                      onChange={(e) => setSize_Women(e)}
                      placeholder="Select an option"
                    />
                    <span className="text-xs">Women's Shoe</span>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="grid gap-y-5 items-start">
          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">END DATE</h6>

            <TextField
              min={moment().add(1, "day").format("YYYY-MM-DD")}
              type="date"
              className="admin_input_1"
              {...register("campaign_deadline", { required: true })}
            />
          </div>
          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">ADD LOCATION</h6>

            <TextField
              className="admin_input_1"
              placeholder="Houston, Texas"
              {...register("location", { required: true })}
            />
          </div>

          {/* NEW DRAG AND DROP SECTION */}
          <div className="flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">CAMPAIGN IMAGES</h6>
            <DndProvider backend={HTML5Backend}>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-y-8">
                {campaignImages.map((image, index) => (
                  <DraggableImage
                    key={image.uuid}
                    image={image}
                    index={index}
                    moveImage={moveImage}
                    deleteImage={deleteImage}
                  />
                ))}
              </div>
            </DndProvider>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              multiple={true}
              onChange={handleNewImageUpload}
              className="hidden"
            />
            <label
              htmlFor="image-upload"
              className="bg-white border-black border text-black py-2 px-4 rounded-md cursor-pointer inline-block text-center mt-4 hover:scale-[1.02] transition-all"
            >
              Add Images
            </label>
            {/* <button className="bg-black text-white py-2 px-4 rounded-md cursor-pointer inline-block text-center hover:bg-gray-800 transition-colors">
              Save
            </button> */}
          </div>

          <div className="flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              CAMPAIGN DESCRIPTION
            </h6>
            <div className="relative grid">
              <TextField
                multiline
                rows={6}
                className="admin_input_1 admin_textarea"
                {...register("campaign_description", { required: true })}
              />
              <button
                type="button"
                className="bg-black !hidden absolute bottom-6 right-4 text-white flex items-center gap-2 py-2 px-4 rounded-md  focus:outline-none focus:shadow-outline transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-[1.01] active:rotate-y-180 active:scale-100"
              >
                <FaCamera />
                <span>Add Photos</span>
              </button>
            </div>
          </div>

          <BlackButton
            text={uuid ? "Edit Campaign" : "Submit"}
            type="submit"
            isDisabled={!campaignImages.length || !selectedSizeTypes.length}
            // isDisabled={!selectedSizeTypes.length}
            className="max-w-[200px]"
            // isLoading={loading}
          />

          {uuid && (
            <div className="flex flex-col gap-5 w-full border-t border-black pt-5 mt-5">
              <h6 className="font-bold text-sm md:text-base">
                ADD CAMPAIGN UPDATES
              </h6>
              {updateImage && (
                <div>
                  <img src={updateImage} alt="" />
                </div>
              )}
              <div className="flex gap-3 md:gap-5 w-full">
                <label
                  htmlFor="updateFileInput"
                  className="bg-black text-white cursor-pointer h-[30px] md:h-[44px] aspect-square rounded-full flex justify-center items-center"
                >
                  <FaCamera className="text-xs sm:text-base" />
                </label>
                <input
                  type="file"
                  id="updateFileInput"
                  onChange={handleUpdateImage}
                  className="hidden"
                />
                <TextField
                  multiline
                  rows={6}
                  value={updatesField}
                  onChange={(e) => setUpdatesField(e.target.value)}
                  className="admin_input_1 flex-1"
                />
              </div>
              <div className="mx-auto">
                <button
                  type="button"
                  onClick={() => {
                    submitUpdate();
                  }}
                  className="bg-black text-white py-2 px-4 rounded-md  focus:outline-none focus:shadow-outline transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-[1.01] active:rotate-y-180 active:scale-100"
                >
                  Post Campaign Update
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </section>
  );
};
