// import aboutImg from '../../assets/images/about.png';
import aboutImg from "../../assets/images/luudu_sneakers.jpg"

export const About = () => {
  return (
    <main className="about_layout my-10">
      <div className="about_layout_content lg:mt-[20px]">
        <h2 className="web_heading bar_above mb-4">About us</h2>
        <p className="web_paragraph_1 mt-2 ">
          Luudu is a platform that revolutionizes the way people purchase
          footwear through group campaigns. The platform operates on the
          principle of collective buying power, where individuals come together
          to back campaigns for footwear. As more people join and support a
          campaign, the price of the footwear gradually decreases until it
          reaches a predetermined end goal. This unique model allows customers
          to benefit from significant price reductions and ensures that once a
          production goal is met, the campaign delivers on the promised items.
        </p>
        <p className="web_paragraph_1 mt-2">
          The core concept behind Luudu is to harness the power of the crowd to
          secure better deals on high-quality footwear. By pooling the
          purchasing power of multiple individuals, the platform empowers
          customers to access exclusive discounts that may not be available
          through traditional retail channels. This collaborative approach
          encourages community engagement and fosters a sense of camaraderie
          among like-minded enthusiasts.
        </p>
        <p className="web_paragraph_1 mt-2">
          When a campaign on Luudu reaches its production goal, the platform
          proceeds to fulfill the orders by manufacturing or procuring and
          delivering the campaigned items. This model ensures that customers who
          have backed a campaign will receive the footwear they desire once the
          target is achieved. The process is transparent and efficient, offering
          participants a sense of fulfillment as they witness the campaign
          progress and witness the price dropping as more people join in.
          Ultimately, Luudu aims to provide a win-win situation for both
          customers and manufacturers/suppliers, as buyers secure affordable
          footwear, and producers benefit from bulk orders and reduced marketing
          costs.
        </p>
        <p className="web_paragraph_1 mt-2">
          To sum it up, Luudu is a pioneering platform that revolutionizes the
          way people purchase footwear through group campaigns. By leveraging
          collective buying power, Luudu allows customers to secure significant
          price reductions on high-quality footwear. With Luudu, the future of
          footwear shopping becomes a collaborative and cost-effective endeavor,
          benefiting both consumers and manufacturers/suppliers alike.
        </p>
      </div>

      <img src={aboutImg} alt="Faith Grey Water Bottle" />
    </main>
  );
};
