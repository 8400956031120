import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store/store";
import { Routes, Route, Navigate } from "react-router-dom";
import { Homepage } from "./pages/website/homepage";
import { About } from "./pages/website/about";
import { Terms } from "./pages/website/terms";
import { HowItWorks } from "./pages/website/how-it-works";
import { Gallery } from "./pages/website/gallery";
import { Login } from "./pages/website/auth/login";
import { Signup } from "./pages/website/auth/signup";
import { Campaigns } from "./pages/website/campaigns";
import { Shop } from "./pages/website/shop";
import { ThankYou } from "./pages/user-account/ThankYou";
import { ProductDetails } from "./pages/website/product-details";
import { Cart } from "./pages/website/cart";
import { LandingLayout } from "./layouts/landing-layout";
import { AdminLayout } from "./layouts/admin-layout";
import { AdminCampaigns } from "./pages/admin/campaigns";
import { AdminNewCampaign } from "./pages/admin/new-campaign";
import { AdminSettings } from "./pages/admin/settings";
import { AdminDashboard } from "./pages/admin/dashboard";
import { AdminGallery } from "./pages/admin/gallery";
import { AdminOrderCampaigns } from "./pages/admin/order-campaigns";
import { useLocation } from "react-router-dom";
import { CampaignSingle } from "./pages/website/campaign-single";
import { UserAccountLayout } from "./layouts/user-account-layout";
import { EditProfile } from "./pages/user-account/EditProfile";
import { MyCampaigns } from "./pages/user-account/MyCampaigns";
import { PaymentHistory } from "./pages/user-account/PaymentHistory";
import { HelpCenter } from "./pages/user-account/HelpCenter";
import { Settings } from "./pages/user-account/Settings";
import { PrivacyPreferences } from "./pages/user-account/PrivacyPreferences";
import ForgotPassword from "./pages/user-account/ForgotPassword";
import { NotificationsPreferences } from "./pages/user-account/NotificationsPreferences";
import PrivacyPreferences2 from "./pages/user-account/PrivacyPreferences2";
import Policies from "./pages/website/Policies";
import { AdminLogin } from "./pages/admin/login";
import { AdminSignup } from "./pages/admin/signup";
import jwt_decode from "jwt-decode";
import { AdminVerifyOtp } from "./pages/admin/verify-otp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { VerifyOtp } from "./pages/website/auth/verify-otp";
import { GoogleCallback } from "./pages/website/auth/google";
import { WebFaq } from "./pages/website/faqs";
import { ContactUs } from "./pages/website/contact-us";
import { Articles } from "./pages/website/articles";
import { SizeGuide } from "./pages/website/size-guide";

type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

interface DecodedToken {
  exp: number;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AdminDashboardProtected({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  const token_a = localStorage.getItem("token_a");
  const user_a = localStorage.getItem("user_a");

  // Check if the token exists and is valid
  if (token_a && user_a) {
    try {
      const decodedToken = jwt_decode<DecodedToken>(token_a);
      const currentTime = Date.now() / 1000;

      // Check if the token is expired
      if (decodedToken.exp > currentTime) {
        return outlet;
      } else {
        console.error("Token is expired");
      }
    } catch (error) {
      // Token is invalid or expired
      console.error("Invalid token:", error);
    }
  }

  localStorage.clear();
  sessionStorage.clear();

  return <Navigate to={{ pathname: authenticationPath }} />;
}

function UserAccountProtected({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user"); //for admin it's user_a

  // Check if the token exists and is valid
  if (token && user) {
    try {
      const decodedToken = jwt_decode<DecodedToken>(token);
      const currentTime = Date.now() / 1000;

      // Check if the token is expired
      if (decodedToken.exp > currentTime) {
        return outlet;
      } else {
        console.error("Token is expired");
      }
    } catch (error) {
      // Token is invalid or expired
      console.error("Invalid token:", error);
    }
  }

  localStorage.clear();
  sessionStorage.clear();
  return <Navigate to={{ pathname: authenticationPath }} />;
}

// mui
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});

function AuthVerifyRoute({ outlet, authenticationPath }: ProtectedRouteProps) {
  const uuid_to_verify = localStorage.getItem("uuid_to_verify");
  if (uuid_to_verify) {
    return outlet;
  }

  return <Navigate to={authenticationPath} />;
}

function BackCampaignProtected({
  outlet,
  authenticationPath,
}: ProtectedRouteProps) {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  if (token && user) {
    try {
      if (sessionStorage.getItem("campaignToBack")) {
        return outlet;
      } else {
        return <Navigate to={authenticationPath} />;
      }
    } catch (error) {
      return <Navigate to="/login" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
}

function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <div>
            <Routes>
              <Route path="/" element={<LandingLayout />}>
                <Route path="/" element={<Homepage />} />
                <Route
                  path="/auth/google/callback"
                  element={<GoogleCallback />}
                />
                <Route path="about" element={<About />} />
                <Route path="articles" element={<Articles />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="how-it-works" element={<HowItWorks />} />
                <Route path="gallery" element={<Gallery />} />
                <Route path="login" element={<Login />} />
                <Route path="privacy" element={<PrivacyPreferences />} />
                <Route path="signup" element={<Signup />} />
                <Route path="shop" element={<Shop />} />
                <Route path="size-guide" element={<SizeGuide />} />
                <Route path="product-details" element={<ProductDetails />} />
                <Route path="terms-of-use" element={<Terms />} />
                <Route path="cart" element={<Cart />} />
                <Route path="payment-successful" element={<ThankYou />} />
                <Route path="forgotPassword" element={<ForgotPassword />} />
                <Route path="campaigns" element={<Campaigns />} />
                <Route path="policies" element={<Policies />} />
                <Route path="faqs" element={<WebFaq />} />
                <Route path="campaigns/:id" element={<CampaignSingle />} />
                <Route
                  path="back-campaign/:uuid"
                  element={
                    <BackCampaignProtected
                      authenticationPath="/campaigns"
                      outlet={<EditProfile></EditProfile>}
                    />
                  }
                ></Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Route>

              <Route
                path="admin"
                element={
                  <AdminDashboardProtected
                    authenticationPath="/admin/login"
                    outlet={<AdminLayout />}
                  />
                }
              >
                <Route path="" element={<AdminDashboard />} />
                <Route path="campaigns" element={<AdminCampaigns />} />
                <Route path="campaigns/new" element={<AdminNewCampaign />} />
                <Route path="campaigns/:uuid" element={<AdminNewCampaign />} />
                <Route path="settings" element={<AdminSettings />} />
                <Route path="gallery" element={<AdminGallery />} />
                <Route
                  path="order-campaigns"
                  element={<AdminOrderCampaigns />}
                />
              </Route>

              <Route
                path="admin/verify"
                element={
                  <AuthVerifyRoute
                    authenticationPath="/admin"
                    outlet={<AdminVerifyOtp></AdminVerifyOtp>}
                  />
                }
              ></Route>

              <Route
                path="admin/login"
                element={<AdminLogin></AdminLogin>}
              ></Route>

              <Route
                path="admin/signup"
                element={<AdminSignup></AdminSignup>}
              ></Route>

              <Route
                path="account/verify"
                element={
                  <AuthVerifyRoute
                    authenticationPath="/login"
                    outlet={<VerifyOtp></VerifyOtp>}
                  />
                }
              ></Route>

              <Route
                path="account"
                element={
                  <UserAccountProtected
                    authenticationPath="/login"
                    outlet={<UserAccountLayout />}
                  />
                }
              >
                <Route path="profile" element={<EditProfile />} />
                <Route path="campaigns" element={<MyCampaigns />} />
                <Route path="payment-history" element={<PaymentHistory />} />
                <Route
                  path="help-center"
                  element={<HelpCenter showTitle={true} />}
                />
                <Route path="settings" element={<Settings />} />
                <Route
                  path="privacy-preferences"
                  element={<PrivacyPreferences2 />}
                />
                <Route
                  path="notifications"
                  element={<NotificationsPreferences />}
                />
              </Route>
            </Routes>
            <ScrollToTop />
          </div>
          <ToastContainer />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
