import styled from 'styled-components';
import bottle from '../assets/black-bottle-back.png';
import { Link } from 'react-router-dom';
import { ICampaignAdmin } from '../models/admin/campaigns';
import { currencyFormat } from '../utils/format-currency';
import LocationIcon from '../assets/icons/location.svg';
import BarImg from '../assets/images/bar.png';
import moment from 'moment';

export const SingleBottle = ({
  campaign,
  currentOrders = 0,
  campaignImage = bottle,
}: {
  campaign: ICampaignAdmin;
  currentOrders: number;
  campaignImage: string;
}) => {
  const currencyView = "$";

  // Append /f_auto to the Cloudinary image URL
  const cloudinaryImageUrl = campaignImage.includes("cloudinary.com")
    ? campaignImage.replace("/upload/", "/upload/q_auto:low,f_auto,c_fill/")
    : campaignImage;

  const formatBarWidth = () => {
    const valueCalculated =
      (currentOrders * 100) / (campaign?.end_goal_order_number ?? 0);
    if (valueCalculated < 50) {
      return valueCalculated + "%";
    } else if (valueCalculated <= 100) {
      return `calc(${valueCalculated}%)`;
    } else {
      return `100%`;
    }
  };

  const getEndsIn = () => {
    const today = moment();
    let endsIn = 0;
    // Check if the deadline has passed
    if (today.isAfter(campaign.campaign_deadline)) {
      endsIn = 0;
    } else {
      // Calculate the number of days ahead
      endsIn = Math.abs(today.diff(campaign.campaign_deadline, "days"));
    }
    return endsIn;
  };

  return (
    <Wrapper>
      <Link to={`/campaigns/${campaign.uuid}`}>
        <div className="group overflow-hidden">
          <img
            // src={campaign.image_url?.split(",")[0] || bottle}
            src={cloudinaryImageUrl || bottle}
            className="group-hover:scale-[1.2] duration-500 w-full h-full object-cover"
            alt="water bottle"
          />
        </div>
      </Link>
      <div className="bg-[#F3F3F3] p-[24px]">
        <h3 className="text-black font-bold text-lg 2xl:text-xl ">
          {" "}
          {campaign.campaign_title}{" "}
        </h3>
        <h4 className="text-black font-bold text-lg 2xl:text-xl 2xl:mt-[8px]">
          {" "}
          {campaign.product_title}{" "}
        </h4>

        <div className="h-[200px] flex items-center justify-center px-2">
          <div className="relative p-[4px] flex-1 border-2 border-solid  h-10 rounded-[21px]">
            <div
              style={{
                width: formatBarWidth(),
              }}
              className="relative h-full rounded-[21px] overflow-hidden"
            >
              <img src={BarImg} className="object-cover h-full w-full" />
              <div className="grey_slanted_bars h-full w-full top-0 left-0 absolute bg-transparent"></div>
            </div>
            <div className="absolute w-full left-0 h-full top-0">
              <div
                className="campaign_dotted_line_with_circles"
                style={{
                  left: `${
                    ((campaign?.production_goal_order_number ?? 0) * 100) /
                    (campaign?.end_goal_order_number ?? 0)
                  }%`,
                }}
              >
                <span className="dot_above">
                  P. G. <br /> {campaign?.production_goal_order_number ?? 0}
                </span>
                <span className="dot_below">
                  {currencyView}{" "}
                  {currencyFormat(campaign?.production_goal_price) ?? 0} <br />{" "}
                  P.G Price
                </span>
              </div>

              <div className="campaign_dotted_line_with_circles right-0">
                <span className="dot_above">
                  E. G. <br /> {campaign?.end_goal_order_number ?? 0}
                </span>
                <span className="dot_below">
                  {currencyView} {currencyFormat(campaign?.end_goal_price) ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-end gap-5 pb-5">
          <div>
            <h5 className="text-[16px] xl:text-[24px] leading-[24px] font-bold">
              {currencyView}{" "}
              <span className="text-[14px] xl:text-[18px]">
                {currencyFormat(campaign.current_price)}
              </span>
            </h5>
            <p className="whitespace-nowrap text-[14px] xl:mt-2 leading-[19px]">
              Current Price
            </p>
          </div>

          <div>
            <h5 className="font-bold leading-[24px] text-[16px] xl:text-[24px]">
              {currentOrders}
            </h5>
            <p className="whitespace-nowrap text-[14px] xl:mt-2 leading-[19px]">
              Current Orders
            </p>
          </div>

          <div>
            <h5 className="font-bold leading-[24px] text-[16px] xl:text-[24px]">
              {getEndsIn()}
            </h5>
            <p className="whitespace-nowrap text-[14px] xl:mt-2 leading-[19px]">
              Days to go
            </p>
          </div>
        </div>

        {/* <div className="flex items-center gap-1 mt-5">
          <img className="!w-5" src={LocationIcon} />
          <span>{campaign.location}</span>
        </div> */}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  color: #000000;
  cursor: pointer;
  /* hover: translateY(-10px); */

  img {
    width: 100%;
    aspect-ratio: 453 / 299;
  }
  .stripe {
    height: 16px;
    background-color: black;
    width: 90%;
    max-width: 280px;
    border-radius: 21px;
  }

  svg {
    font-weight: bold;
    width: 14px;
    display: inline;
  }
`;
