import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FiAlertCircle, FiMail, FiUser } from "react-icons/fi";

interface JoinCommunityFormProps {
  first_name: string;
  last_name: string;
  email: string;
}

interface FormProps {
  status: string;
  message: string;
  onValidated: (values: { EMAIL: string; MERGE1: string; MERGE2: string }) => void;
}

const JoinCommunityForm: React.FC<FormProps> = ({ status, message, onValidated }) => {
  const initialValues: JoinCommunityFormProps = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
  });

  const onSubmit = (values: JoinCommunityFormProps) => {
    // console.log("Form data", values);
    onValidated({
      EMAIL: values.email,
      MERGE1: values.first_name,
      MERGE2: values.last_name,
    });
  };

  return (
    <div className="max-w-4xl mx-auto mt-12 p-8 bg-gradient-to-br from-white to-gray-100 rounded-3xl shadow-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center">
          <h2 className="text-3xl font-bold text-[#10152B] mb-4">
            JOIN OUR COMMUNITY
          </h2>
          <p className="text-gray-600 mb-6">
            Sign up to be the first to hear about our limited-edition
            collection, exclusive products and more.
          </p>
          {/* {status === "sending" && (
            <div className="bg-blue-100 text-blue-700 p-3 rounded-lg mb-4">
              Sending...
            </div>
          )}
          {status === "error" && (
            <div
              className="bg-red-100 text-red-700 p-3 rounded-lg mb-4"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )} */}
          {status === "success" && (
            <div
              className="bg-green-100 text-green-700 p-3 rounded-lg mb-4"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="space-y-4">
              <div className="relative">
                <Field
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
                  placeholder="First Name"
                />
                <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <ErrorMessage name="first_name">
                  {(msg) => (
                    <div className="text-red-600 text-sm mt-1 flex items-center">
                      <FiAlertCircle size={16} className="mr-1" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <div className="relative">
                <Field
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
                  placeholder="Last Name"
                />

                <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <ErrorMessage name="last_name">
                  {(msg) => (
                    <div className="text-red-600 text-sm mt-1 flex items-center">
                      <FiAlertCircle size={16} className="mr-1" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <div className="relative">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
                  placeholder="Email"
                />
                <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <ErrorMessage name="email">
                  {(msg) => (
                    <div className="text-red-600 text-sm mt-1 flex items-center">
                      <FiAlertCircle size={16} className="mr-1" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <button
                type="submit"
                className="w-full bg-[#10152B] text-white py-3 px-4 rounded-xl hover:bg-[#1c2647] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1"
              >
                Subscribe
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunityForm;